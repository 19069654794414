<script>
  // Import the functions you need from the SDKs you need
  import { initializeApp } from "firebase/app";
  import { getAnalytics } from "firebase/analytics";
  import { getAuth } from "firebase/auth";
  import { getFirestore } from "firebase/firestore";
  import { getStorage } from "firebase/storage";
  import { getFunctions, httpsCallable } from "firebase/functions";

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCo11QzCS5t2MkXV14xxz_tmhKBb-vAExY",
    authDomain: "calendar-ihtfy.firebaseapp.com",
    projectId: "calendar-ihtfy",
    storageBucket: "calendar-ihtfy.appspot.com",
    messagingSenderId: "884932245525",
    appId: "1:884932245525:web:b1ff690b9ce3d26f77b61c",
    measurementId: "G-BB3ET73W7P",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const functions = getFunctions(app);
  // const addMessage = httpsCallable("addMessage");

  import Calendar from "./components/Calendar.svelte";
  import EventEditor from "./components/EventEditor.svelte";
  import EventTable from "./components/EventTable.svelte";
  import Settings from "./components/Settings.svelte";
  import { Tabs } from "attractions";

  let selectedTab = "EVENTS";
</script>

<main>
  <message> COMING SOON! </message>
  <!-- <Tabs
    name="pages"
    items={["PREVIEW", "EVENTS", "SETTINGS"]}
    bind:value={selectedTab}
  />
  <div class="column">
    {#if selectedTab === "Preview"}
      <Calendar />
    {:else if selectedTab === "EVENTS"}
      <EventEditor />
      <EventTable />
    {:else if selectedTab === "SETTINGS"}
      <Settings />
    {/if}
  </div> -->
</main>

<style>
  message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    font-weight: bold;
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  /* .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
</style>
